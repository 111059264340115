<template>
  <FormBaseForm
    :schema="FIELD_FORM_SCHEMA"
    :state="state"
    class="space-y-4"
    @submit="(data: typeof state, form: Record<string, CallableFunction>) => $emit('submit', data, form)"
  >
    <FormFieldBase v-model="state" />
    <slot name="footer" />
  </FormBaseForm>
</template>

<script lang="ts" setup>
import type { Field } from '#field/types'
import { FIELD_FORM_SCHEMA } from '#field/constant'

const props = defineProps({
  initialValue: {
    type: Object as PropType<Partial<Field>>,
  },
})

defineEmits<{
  (e: 'submit', data: typeof state, form: Record<string, CallableFunction>): void
}>()

const state = reactive({
  name: '',
  description: '',
  ...props.initialValue,
})
</script>
